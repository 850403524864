<template>
  <div>
    <vb-lockscreen />
  </div>
</template>
<script>
import VbLockscreen from '@/components/Auth/Lockscreen'
export default {
  components: {
    VbLockscreen,
  },
}
</script>
